// theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: '"degular", sans-serif',
    h1: {
      color: "#272726",
      fontFamily: '"ivymode", serif',
    },
    h2: {
      color: "#272726",
      letterSpacing: "6px",
      fontFamily: '"ivymode", monospace',
    },
    h3: {
      color: "#272726",
      letterSpacing: "2px",

      fontSize: "1.5rem",
      fontFamily: '"ivymode", monospace',
    },
    body1: {
      fontFamily: '"degular", serif',
      color: "#272726",
      fontWeight: 500,
      letterSpacing: "3.3px",
      fontStyle: "normal",
    },
    body2: {
      fontFamily: '"degular", serif',
      fontWeight: 400,
      fontStyle: "normal",
      color: "#272726",
    },
    caption: {
      color: "#272726",
      textAlign: "center",
      fontFamily: "degular",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "25.042px", // 175%
      letterSpacing: "2.003px",
      textTransform: "uppercase",
    },
    subtitle1: {
      textAlign: "center",
      fontFamily: "ivymode",
      fontSize: "35px",
      fontStyle: "italic",
      fontWeight: 300,
      lineHeight: "42px", // 120%
      letterSpacing: "5.25px",
    },
    subtitle2: {
      color: "#272726",
      fontFamily: "degular",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "28px",
      letterSpacing: "1.25px",
    },
  },
  palette: {
    primary: {
      main: "#272726",
      light: "#898989",
      dark: "#2B2B29",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ece9e0",
      light: "#ba68c8",
      dark: "#7b1fa2",
      contrastText: "#fff",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
      contrastText: "#fff",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "#e65100",
      contrastText: "#fff",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
      contrastText: "#fff",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
      contrastText: "#fff",
    },
  },
  breakpoints: {
    values: {
      xs: 375,
      sm: 600,
      md: 900,
      lg: 1380,
      xl: 1800,
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "transparent",
          boxShadow: "none",
          padding: 0,
          maxWidth: "none",
          // Add any additional custom styles here
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 2,
        },
      },
    },
  },
});

export default theme;
