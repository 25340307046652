import React, { useState, Suspense } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { ParallaxProvider } from "react-scroll-parallax";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import theme from "./theme";
import "./App.css";
import { useMediaQuery } from "@uidotdev/usehooks";
// import Impressum from "./pages/Impressum/Impressum";
const MobileScrollLayout = React.lazy(
  () => import("./components/Layout/MobileScrollLayout")
);
const YogaSpace = React.lazy(() => import("./pages/YogaSpace/YogaSpace"));
const ParallaxLayout = React.lazy(
  () => import("./components/Layout/ParallaxLayout")
);
function App() {
  const [showMainContent, setShowMainContent] = useState(false);
  const isTabletOrMobile = useMediaQuery("(max-width: 1090px)"); // Definisci il breakpoint per tablet e mobile
  React.useEffect(() => {
    // setTimeout(() => {
    //   setShowMainContent(true);
    // }, 3000);
    setShowMainContent(true);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ParallaxProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                      }}
                    >
                      <CircularProgress
                        sx={{
                          color: "white",
                        }}
                      />
                    </div>
                  }
                >
                  {/* {!showMainContent && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                      }}
                    >
                      <CircularProgress
                        sx={{
                          color: "white",
                        }}
                      />
                    </div>
                  )} */}
                  {isTabletOrMobile ? (
                    <div style={{ opacity: showMainContent ? 1 : 0 }}>
                      <MobileScrollLayout />
                    </div>
                  ) : (
                    <ParallaxLayout />
                  )}
                </Suspense>
              }
            />
            <Route
              path="/yogaspace"
              element={
                <Suspense
                  fallback={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                      }}
                    >
                      <CircularProgress
                        sx={{
                          color: "white",
                        }}
                      />
                    </div>
                  }
                >
                  {isTabletOrMobile ? (
                    <MobileScrollLayout isYoga />
                  ) : (
                    <YogaSpace />
                  )}
                </Suspense>
              }
            />
          </Routes>
        </Router>
      </ParallaxProvider>
    </ThemeProvider>
  );
}

export default App;
